<template>
	<el-card>
		<advanced-search-bar>
			<template>
				<form-item-sites :abbr="true" :multiple="false" :local="true" v-model="queryForm.website" :parent="false" @onload="q.website=queryForm.website;searchEvent()" :clearable="false"></form-item-sites>
				<form-item-countries v-model="queryForm.countryCode" :site="queryForm.website"></form-item-countries>
				<form-item label="语言:" style="width:200px;">
					<el-select v-model="queryForm.languageCode" size="mini" clearable filterable>
						<el-option v-for="language in shortLanguages" :label="language" :value="language"></el-option>
					</el-select>
				</form-item>
				<form-item label="发布状态:" style="width:200px;">
					<el-select v-model="queryForm.active" size="mini" clearable="">
						<el-option label="开启" :value="true"></el-option>
						<el-option label="暂停" :value="false"></el-option>
					</el-select>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" @click="form.website=queryForm.website;visible.add=true">添加配置</el-button>
				</form-item>
			</template>
		</advanced-search-bar>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
		<alert v-if="multipleSelection.length > 0">
			已选择 <span class="text-primary">{{multipleSelection.length}}</span> 个。
			<el-dropdown @command="handleCommand">
			      <span class="text-primary">
			        批量操作
			      </span>
			      <el-dropdown-menu slot="dropdown">
			        <el-dropdown-item command="active">发布</el-dropdown-item>
			        <el-dropdown-item command="deactive">取消发布</el-dropdown-item>
			      </el-dropdown-menu>
			    </el-dropdown>
		</alert>
		<country-language-table class="mt-3 w-100" :tableData="tableData" @handleSelectionChange="handleSelectionChange" @changePublish="changeActive" @editRow="editRow"></country-language-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
		<el-dialog :visible.sync="visible.add" :before-close="handleClose" style="min-width:900px;">
			<el-form ref="form" :model="form" :rules="rules" :hide-required-asterisk="true" label-width="100px">
				<el-form-item-sites :abbr="true" v-model="form.website" label-width="100px" prop="website"></el-form-item-sites>
				<el-form-item-countries v-model="form.countryCode" label-width="100px" :site="form.website"></el-form-item-countries>
				<el-form-item label="语言缩写:" prop="languageCode">
					<el-select v-model="form.languageCode" placeholder="请选择2位大写字母,示例:EN" class="w-100" filterable>
						<el-option v-for="language in shortLanguages" :label="language" :value="language"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="语言全称:" prop="languageFullName">
					<el-select v-model="form.languageFullName" placeholder="请选择,示例:English(All)" class="w-100" filterable multiple>
						<el-option v-for="language in fullLanguages" :label="language" :value="language"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.add = false">取 消</el-button>
				<el-button type="primary" @click="doCreateOrUpdate">确 定</el-button>
			</div>
		</el-dialog>
	</el-card>
</template>

<script>
	import {
		mapState,
		mapActions
	} from 'vuex'
	import CountryLanguageTable from '@/components/contents/country-language-table.vue'
	import config from './minixs/index.js'
	import Lodash from 'lodash'
	const DEFAULT_FORM = {
		countryCode: '',
		languageCode: '',
		languageFullName: [],
		website: ''
	}
	export default {
		mixins:[config],
		components: {
			CountryLanguageTable
		},
		data() {
			return {
				queryForm: {
					countryCode: '',
					languageCode: '',
					active: '',
					website: ''
				},
				tableData: [],
				q: {},
				visible: {
					add: false
				},
				form: Lodash.cloneDeep(DEFAULT_FORM),
				rules: {
					countryCode: [{
						required: true,
						message: '国家缩写不能为空'
					}],
					languageCode: [{
						required: true,
						message: '语言缩写不能为空'
					}],
					languageFullName: [{
						required: true,
						message: '语言全称不能为空',
						type: 'array'
					}],
					website: [{
						require: true,
						message: '站点不能为空'
					}]
				},
				fetchOnCreated: false,
				namespace: 'fb-contents-language'
			}
		},
		computed: {
			...mapState({
				shortLanguages: state => state.content.shortLanguages,
				fullLanguages: state => state.content.fullLanguages
			})
		},
		mounted() {
			this.getShortLanguages()
			this.getFullLanguages()
		},
		methods: {
			...mapActions([
				'getShortLanguages',
				'getFullLanguages'
			]),
			fillForm(data) {
				this.form = {
					...Lodash.cloneDeep(DEFAULT_FORM),
					...data
				}
			},
		},
		watch: {
			'visible.add'(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						this.$refs.form.clearValidate()
					})
				} else {
					this.form = Lodash.cloneDeep(DEFAULT_FORM)
				}
			}
		}
	}
</script>

<style>
</style>
